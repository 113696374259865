body {
  background-color: rgb(250, 250, 250);
}

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  /* margin: 5%;
  border: 5%; */
}

/* .image {
  width: 370px;
  margin-top: 50px;
} */

/* @media only screen and (max-width: 770px) {
  .container {
      margin: 0 10%;
  }

  .image {
      width: 100%;
  }
} */