.container {
    display: flex;
    width: 80%;
}
.lineChart {
    margin: 25px;
}

.chats {
    display:  inline-block;
    
}
/* @media only screen and (max-width: 770px) {
    .container {
        width: 100%;
    }
} */
